import { Controller } from "@hotwired/stimulus";
import { Modal } from "bootstrap";

// Connects to data-controller="recaptcha-v2"
export default class extends Controller {
  connect() {
    const modal = new Modal(this.element, {});
    modal.show();

    window.recaptchaSubmit = function recaptchaSubmit() {
      const form = document.getElementById("sign-up-form");
      form.setAttribute("verified", "true");
      // eslint-disable-next-line no-undef
      Turbo.navigator.submitForm(form);
    };
  }
}
