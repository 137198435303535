/* eslint-disable no-undef */
import { Controller } from "@hotwired/stimulus";
import "bootstrap-datepicker";

// Connects to data-controller="datetimepicker"
export default class extends Controller {
  connect() {
    $("input.datepicker").datepicker({
      format: "dd.mm.yyyy",
      clearBtn: true,
      autoclose: true,
      todayHighlight: true,
      orientation: "bottom auto",
    });

    $(".time-dropdown-menu li a").click(function () {
      $(this).parents(".input-group").find(".timepicker").val($(this).text());
    });
  }
}
