/* eslint-disable no-undef */
import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="analytics"
export default class extends Controller {
  // It doesn't seem to be working. I'll leave it here just in case.
  // Need to do as in the hub, link to documentation:
  // https://cloud.google.com/appengine/docs/flexible/integrating-with-analytics?tab=ruby#top
  connect() {
    $(document).on("page:change", function () {
      if (window._gaq != null) {
        return _gaq.push(["_trackPageview"]);
      } else if (window.pageTracker != null) {
        return pageTracker._trackPageview();
      }
    });
  }
}
